// services/apiService.js
import axiosInstance from './axiosConfig';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to get the token from local storage
const getAuthToken = () => {
  return localStorage.getItem('token');
};

// Add Power BI config fetch function
export const fetchPowerBIConfig = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;

    const response = await axiosInstance.get(
      `${API_BASE_URL}/powerbi/report/${user.client_id}/${user.product_id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching Power BI config:', error);
    throw error;
  }
};

// Existing functions
export const fetchDataFromAPI = async (platform) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/data?platform=${platform}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};

export const fetchOrderDetails = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/data`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

export const fetchDashboardData = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/orders`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const fetchPodata = async (Ponumber) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/items-by-po/${Ponumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order summaries:', error);
    throw error;
  }
};