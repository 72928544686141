import React, { useEffect, useState } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Loader2 } from 'lucide-react';
import { fetchPowerBIConfig } from '../routes/AppRoutes';

const PowerBIReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportConfig, setReportConfig] = useState(null);

  const getReportConfig = (reportId, embedUrl, embedToken) => ({
    type: 'report',
    id: reportId,
    embedUrl: embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken: embedToken,
    settings: {
      panes: {
        pageNavigation: {
          visible: true,
          position: models.PageNavigationPosition.Left
        }
      }
    }
  });

  useEffect(() => {
    const loadReport = async () => {
      try {
        const response = await fetchPowerBIConfig();
        
        if (!response.success) {
          throw new Error(response.error || 'Failed to load report configuration');
        }

        const { reportId, embedUrl, embedToken } = response.data;
        setReportConfig(getReportConfig(reportId, embedUrl, embedToken));
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading PowerBI report:', JSON.stringify(err, null, 2));
        setError(err.message || 'Failed to load report');
        setIsLoading(false);
      }
    };

    loadReport();
  }, []);

  const handleRetry = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await fetchPowerBIConfig();
      if (!response.success) {
        throw new Error(response.error || 'Failed to load report configuration');
      }

      const { reportId, embedUrl, embedToken } = response.data;
      setReportConfig(getReportConfig(reportId, embedUrl, embedToken));
      setIsLoading(false);
    } catch (err) {
      console.error('Error retrying PowerBI report load:', JSON.stringify(err, null, 2));
      setError(err.message || 'Failed to load report');
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <div className="p-6 bg-white rounded-lg shadow-lg text-center">
          <p className="text-red-500 text-lg">{error}</p>
          <button 
            onClick={handleRetry}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (isLoading || !reportConfig) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <div className="p-6 bg-white rounded-lg shadow-lg text-center">
          <Loader2 className="w-12 h-12 animate-spin mx-auto text-blue-500" />
          <p className="mt-4 text-gray-600">Loading Power BI Report...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full" style={{ height: '100%', minHeight: '100%' }}>
      <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName="h-full w-full"
        eventHandlers={
          new Map([
            ['loaded', () => {
              console.log('Report loaded');
              setIsLoading(false);
            }],
            ['rendered', () => console.log('Report rendered')],
            ['error', (event) => {
              console.error('PowerBI Report Error:', JSON.stringify(event.detail, null, 2));
              setError('Failed to render report');
            }]
          ])
        }
      />
    </div>
  );
};

export default PowerBIReport;
