import React from 'react';
import { ShieldOff, HelpCircle, Mail, AlertCircle, RefreshCw } from 'lucide-react';

const AuthorizationDenied = () => {
  const handleClick = (e) => {
    e.preventDefault();
    // Your click handling logic
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-8 max-w-md w-full shadow-xl">
        <div className="text-center mb-6">
          <div className="w-20 h-20 mx-auto mb-4 bg-red-100 rounded-full flex items-center justify-center">
            <ShieldOff className="text-red-500 w-10 h-10" />
          </div>
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Access Denied</h1>
          <p className="text-gray-600">You don't have permission to access this page.</p>
        </div>
        
        <div className="bg-gray-100 rounded-lg p-4 mb-6">
          <div className="flex items-center justify-center mb-2">
            <AlertCircle className="text-yellow-500 mr-2" />
            <span className="text-gray-700 font-semibold">Unauthorized Access</span>
          </div>
          <p className="text-gray-600 text-center text-sm">
            Please contact your administrator for assistance.
          </p>
        </div>
        
        <div className="flex justify-center space-x-4 mb-6">
          <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md flex items-center transition duration-300 ease-in-out hover:bg-blue-600">
            <HelpCircle className="mr-2" size={18} />
            <span>Get Help</span>
          </button>
          <button className="bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded-md flex items-center transition duration-300 ease-in-out hover:bg-gray-300">
            <RefreshCw className="mr-2" size={18} />
            <span>Retry</span>
          </button>
        </div>

        <div className="text-center flex justify-center space-x-6">
          <button 
            className="text-blue-500 hover:text-blue-700 transition-colors duration-300 flex items-center"
            onClick={handleClick}
          >
            <Mail className="mr-2" size={16} />
            <span>Contact Admin</span>
          </button>
          <button 
            className="text-blue-500 hover:text-blue-700 transition-colors duration-300 flex items-center"
            onClick={handleClick}
          >
            <AlertCircle className="mr-2" size={16} />
            <span>Report Issue</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationDenied;