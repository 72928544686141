import React from 'react';
import { Rocket, BarChart2, LineChart, PieChart, TrendingUp } from 'lucide-react';

const UnderConstruction = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-50 to-blue-50 flex flex-col justify-center items-center p-4 relative overflow-hidden">
      {/* Analytics-themed background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {/* Floating chart elements */}
        <div className="absolute top-[10%] left-[10%] animate-float-slow opacity-20">
          <BarChart2 className="w-16 h-16 text-blue-500" />
        </div>
        <div className="absolute top-[20%] right-[15%] animate-float opacity-20">
          <LineChart className="w-20 h-20 text-indigo-500" />
        </div>
        <div className="absolute bottom-[15%] left-[20%] animate-float-delayed opacity-20">
          <PieChart className="w-24 h-24 text-purple-500" />
        </div>
        <div className="absolute bottom-[25%] right-[10%] animate-float-slow opacity-20">
          <TrendingUp className="w-16 h-16 text-blue-500" />
        </div>

        {/* Data points and connection lines */}
        <div className="absolute inset-0">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1.5 h-1.5 bg-blue-400/30 rounded-full animate-pulse"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 2}s`,
              }}
            />
          ))}
        </div>
      </div>

      {/* Main content */}
      <div className="relative max-w-md w-full mx-auto text-center space-y-6">
        {/* Logo */}
        <div className="w-64 h-64 mx-auto mb-2">
          <img 
            src="https://i.ibb.co/vDqg4jC/no-Bg-Color.png" 
            alt="DeltaAnalytics.ai Logo" 
            className="w-full h-full object-contain"
          />
        </div>

        {/* Title */}
        <h1 className="text-4xl font-bold text-gray-800 -mt-4">
          Something Amazing is Coming
        </h1>

        {/* Description */}
        <p className="text-xl text-gray-600">
          We're crafting the future of data analytics.
        </p>

        {/* Rocket animation */}
        <div className="relative w-16 h-16 mx-auto animate-float">
          <Rocket className="w-full h-full text-blue-500" />
        </div>

        {/* Launch indicator */}
        <div className="flex items-center justify-center gap-2">
          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
          </span>
          <span className="text-gray-600 font-medium">Launching Soon</span>
        </div>
      </div>

      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0) rotate(0deg); }
          50% { transform: translateY(-10px) rotate(2deg); }
        }

        @keyframes float-slow {
          0%, 100% { transform: translateY(0) rotate(0deg); }
          50% { transform: translateY(-15px) rotate(-2deg); }
        }

        .animate-float {
          animation: float 4s ease-in-out infinite;
        }

        .animate-float-delayed {
          animation: float 4s ease-in-out 1s infinite;
        }

        .animate-float-slow {
          animation: float-slow 6s ease-in-out infinite;
        }

        /* Add subtle connection lines between data points */
        .data-line {
          position: absolute;
          background: linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.1), transparent);
          height: 1px;
          width: 100px;
          transform-origin: left;
        }
      `}</style>
    </div>
  );
};

export default UnderConstruction;