import React from 'react';
import './settings.css';
import { X } from 'lucide-react';

const SettingsPopup = ({ isOpen, onClose, user }) => {
  if (!isOpen) return null;

  return (
    <div className="settings-popup-overlay">
      <div className="settings-popup">
        <div className="settings-header">
          <h2>Settings</h2>
          <button className="close-btn" onClick={onClose}>
            <X size={20} />
          </button>
        </div>

        <div className="settings-content">
          {/* Add your settings content here */}
          <div className="settings-section">
            <h3>Profile Settings</h3>
            {/* Add profile settings */}
          </div>

          <div className="settings-section">
            <h3>Notification Settings</h3>
            {/* Add notification settings */}
          </div>

          <div className="settings-section">
            <h3>Theme Settings</h3>
            {/* Add theme settings */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;