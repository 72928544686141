import React, { useState, useEffect, useRef } from 'react';
import { 
  PieChart, 
  Database, 
  Container, 
  X, 
  ChevronDown,
  TrendingUp,
  DollarSign,
  Package,
  Tag,
  LogOut,
  Menu,
  User
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import './Dashboard.css';
import UnderConstruction from './UnderConstruction';
import SettingsPopup from './settings';
import FullScreenPowerBIEmbed from './powerbi';
import NotificationBar from './NotificationBar';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [user, setUser] = useState({
    name: '',
    imageUrl: '',
    gender: 'male',
    role: '',
    department: '',
    lastActive: new Date(),
  });
  const [activeContent, setActiveContent] = useState('DSA');
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [mailNotifications, setMailNotifications] = useState([
    { id: 1, message: 'New email from John', read: false, type: 'info', time: '2 min ago' },
    { id: 2, message: 'Meeting reminder', read: false, type: 'warning', time: '1 hour ago' },
    { id: 3, message: 'Project update', read: false, type: 'success', time: '3 hours ago' },
  ]);
  const [bellNotifications, setBellNotifications] = useState([
    { id: 1, message: 'New order received', read: false, type: 'success', time: '5 min ago' },
    { id: 2, message: 'Payment processed', read: false, type: 'info', time: '30 min ago' },
    { id: 3, message: 'Stock alert', read: false, type: 'warning', time: '1 hour ago' },
    { id: 4, message: 'New feature available', read: false, type: 'info', time: '2 hours ago' },
    { id: 5, message: 'System maintenance tonight', read: false, type: 'warning', time: '4 hours ago' },
  ]);
  const [showMailNotifications, setShowMailNotifications] = useState(false);
  const [showBellNotifications, setShowBellNotifications] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mailNotificationRef = useRef(null);
  const bellNotificationRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (expandedMenu === 'Ecom Dashboard' && activeContent === 'Home') {
      setActiveContent('Sales');
    }
  }, [expandedMenu, activeContent]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setUser({
        name: `${userData.first_name} ${userData.last_name}`,
        imageUrl: userData.profile_picture || `https://ui-avatars.com/api/?name=${userData.first_name}+${userData.last_name}&background=4f46e5&color=fff`,
        gender: userData.gender || 'male',
        role: userData.role || 'Team Member',
        department: userData.department || 'General',
        lastActive: new Date(),
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mailNotificationRef.current && !mailNotificationRef.current.contains(event.target)) {
        setShowMailNotifications(false);
      }
      if (bellNotificationRef.current && !bellNotificationRef.current.contains(event.target)) {
        setShowBellNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
    }, 2000);
    return () => clearTimeout(timer);
  }, [activeContent]);

  const menuItems = [
    // { 
    //   icon: ShoppingBag, 
    //   text: 'Order Management',
    //   subItems: [
    //     { icon: Home, text: 'Home' },
    //     { 
    //       icon: Database, 
    //       text: 'Platforms',
    //       platforms: platforms
    //     }
    //   ]
    // },
    { icon: PieChart, text: 'DSA' },
    { 
      icon: Database,
      text: 'Ecom Dashboard',
      subItems: [
        { icon: TrendingUp, text: 'Sales' },
        { icon: DollarSign, text: 'Revenue' },
        { icon: Package, text: 'Products' },
        { icon: Tag, text: 'Marketing Campaigns' }
      ]
    },
    { icon: User, text: 'Admin' },
  ];
  const renderContent = () => {
    switch (activeContent.toLowerCase()) {
      case 'sales':
      case 'revenue':
      case 'products':
      case 'marketing campaigns':
        return <UnderConstruction />;
      case 'admin':
        return <AdminDashboard />;
      case 'dsa':
        return (
          <div className="dashboard-container">
            <div className="powerbi-wrapper glass-effect">
              <FullScreenPowerBIEmbed reportType="dsa" />
            </div>
          </div>
        );
      default:
        return <UnderConstruction />;
    }
  };

  const handleMenuItemClick = (menuText) => {
    if (menuText === 'Order Management' || menuText === 'Ecom Dashboard') {
      setExpandedMenu(prevMenu => prevMenu === menuText ? null : menuText);
      if (expandedMenu !== menuText) {
        setActiveContent(menuText === 'Order Management' ? 'Home' : 'Sales');
      }
    } else {
      setActiveContent(menuText);
      setExpandedMenu(null);
    }
  };

  const handleSubItemClick = (subItemText, platform = null, event) => {
    if (event) {
      event.stopPropagation();
    }
    setActiveContent(subItemText);
  };

  const handleLogout = () => {
    setLogoutStatus('loading');
    
    setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setLogoutStatus('success');
      navigate('/login');
    }, 1500);
  };

  const toggleNotifications = (type) => {
    if (type === 'mail') {
      setShowMailNotifications(prev => !prev);
      setShowBellNotifications(false);
    } else {
      setShowBellNotifications(prev => !prev);
      setShowMailNotifications(false);
    }
  };

  const clearAllNotifications = (type) => {
    if (type === 'mail') {
      setMailNotifications([]);
    } else {
      setBellNotifications([]);
    }
  };

  const removeNotification = (type, id) => {
    const setNotifications = type === 'mail' ? setMailNotifications : setBellNotifications;
    setNotifications(prevNotifications => prevNotifications.filter(notif => notif.id !== id));
  };

  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <aside className={`sidebar ${isMobileMenuOpen ? '' : 'collapsed'}`}>
        <div className="sidebar-header">
          <div className="brand-container">
            <div className="brand-section">
              <div className="brand-icon">
                <img src="https://i.ibb.co/wQMtL7D/orange.png" alt="Orange icon" className="orange-icon" />
              </div>
              <span className="product-name">orange</span>
            </div>
            <div className="powered-by">
              <span className="powered-text">powered by</span>
              <img src="https://i.ibb.co/vDqg4jC/no-Bg-Color.png" alt="Logo" className="company-logo" />
            </div>
          </div>
        </div>
        
        <nav className="sidebar-nav">
          {menuItems.map((item, index) => (
            <div key={index} className="nav-group">
              <div 
                className={`nav-item ${activeContent === item.text ? 'active' : ''}`}
                onClick={() => handleMenuItemClick(item.text)}
              >
                <item.icon className="nav-icon" />
                <span>{item.text}</span>
                {item.subItems && (
                  <ChevronDown className={`arrow ${expandedMenu === item.text ? 'expanded' : ''}`} />
                )}
              </div>
              
              {/* Submenu rendering */}
              {item.subItems && expandedMenu === item.text && (
                <div className="submenu">
                  {item.subItems.map((subItem, idx) => (
                    <div
                      key={idx}
                      className={`submenu-item ${activeContent === subItem.text ? 'active' : ''}`}
                      onClick={(event) => handleSubItemClick(subItem.text, subItem.platform, event)}
                    >
                      <subItem.icon className="submenu-icon" />
                      <span>{subItem.text}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>

        <div className="sidebar-footer">
          <button 
            className="logout-btn" 
            onClick={handleLogout}
            disabled={logoutStatus === 'loading'}
          >
            <LogOut className="logout-icon" />
            <span>Logout</span>
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <main className="main-container">
        <header className="top-header glass-effect">
          <div className="header-left">
            <button className="menu-toggle glass-effect" onClick={() => setIsMobile(!isMobile)}>
              <Container />
            </button>
            <h1 className="page-title gradient-text">{activeContent}</h1>
          </div>

          <NotificationBar 
            notifications={[...bellNotifications, ...mailNotifications]}
            showNotifications={showBellNotifications || showMailNotifications}
            toggleNotifications={toggleNotifications}
            clearAllNotifications={clearAllNotifications}
            removeNotification={removeNotification}
            notificationRef={bellNotificationRef}
            setIsSettingsOpen={setIsSettingsOpen}
            user={user}
          />
        </header>

        <div className="content">
          {renderContent()}
        </div>
      </main>

      {isSettingsOpen && (
        <div className="settings-overlay" onClick={() => setIsSettingsOpen(false)}>
          <div className="settings-modal" onClick={e => e.stopPropagation()}>
            <SettingsPopup
              isOpen={isSettingsOpen}
              onClose={() => setIsSettingsOpen(false)}
              user={user}
            />
          </div>
        </div>
      )}

      {isMobile && (
        <button 
          className="mobile-menu-toggle"
          onClick={toggleMobileMenu}
          aria-label="Toggle navigation menu"
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      )}
    </div>
  );
};

export default Dashboard;