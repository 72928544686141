import React, { useState, useEffect } from 'react';
import { 
  Bell, 
  Settings, 
  X, 
  Trash2,
  Clock,
  CheckCircle,
  AlertTriangle,
  Info
} from 'lucide-react';
import './NotificationBar.css';

const NotificationBar = ({ 
  notifications, 
  showNotifications,
  toggleNotifications,
  clearAllNotifications,
  removeNotification,
  notificationRef,
  setIsSettingsOpen,
  user
}) => {
  const [lastActive, setLastActive] = useState(new Date());
  const [userStatus, setUserStatus] = useState('online'); // 'online', 'away', 'offline'

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificationRef]);

  // Update last active time on user interaction
  useEffect(() => {
    const updateLastActive = () => {
      setLastActive(new Date());
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', updateLastActive);
    window.addEventListener('keydown', updateLastActive);
    window.addEventListener('click', updateLastActive);
    window.addEventListener('scroll', updateLastActive);

    // Check user status every 30 seconds
    const statusInterval = setInterval(() => {
      const timeSinceLastActive = new Date() - lastActive;
      const minutesSinceLastActive = timeSinceLastActive / (1000 * 60);

      if (minutesSinceLastActive > 5) {
        setUserStatus('offline');
      } else if (minutesSinceLastActive > 3) {
        setUserStatus('away');
      } else {
        setUserStatus('online');
      }
    }, 30000);

    return () => {
      // Cleanup event listeners
      window.removeEventListener('mousemove', updateLastActive);
      window.removeEventListener('keydown', updateLastActive);
      window.removeEventListener('click', updateLastActive);
      window.removeEventListener('scroll', updateLastActive);
      clearInterval(statusInterval);
    };
  }, [lastActive]);

  // Update the defaultAvatar to handle localStorage fallback
  const defaultAvatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(
    localStorage.getItem('userName') || user?.name || 'User'
  )}&background=4f46e5&color=fff`;

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle size={14} className="notification-icon success" />;
      case 'warning':
        return <AlertTriangle size={14} className="notification-icon warning" />;
      case 'info':
      default:
        return <Info size={14} className="notification-icon info" />;
    }
  };

  return (
    <div className="header-right">
      <div className="notification-wrapper">
        <button 
          className="icon-button notification-btn"
          onClick={() => toggleNotifications('all')}
        >
          <Bell size={20} strokeWidth={1.5} />
          {notifications.length > 0 && (
            <span className="notification-badge">{notifications.length}</span>
          )}
        </button>

        {showNotifications && (
          <div className="notification-dropdown" ref={notificationRef}>
            <div className="notification-header">
              <h3>Notifications</h3>
              <button className="clear-all-btn" onClick={() => clearAllNotifications('all')}>
                <Trash2 size={14} /> Clear all
              </button>
            </div>
            <div className="notification-list">
              {notifications.length === 0 ? (
                <div className="no-notifications">No new notifications</div>
              ) : (
                notifications.map(notification => (
                  <div key={notification.id} className="notification-item">
                    <div className="notification-content">
                      {getNotificationIcon(notification.type)}
                      <div className="notification-text">
                        <span className="notification-message">{notification.message}</span>
                        <span className="notification-time">
                          <Clock size={12} /> {notification.time}
                        </span>
                      </div>
                    </div>
                    <button 
                      className="remove-notification"
                      onClick={() => removeNotification('all', notification.id)}
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>

      <button 
        className="icon-button"
        onClick={() => setIsSettingsOpen(true)}
      >
        <Settings size={20} strokeWidth={1.5} />
      </button>

      <div className="user-avatar-wrapper">
        <div className="avatar-container">
          <img
            src={user?.profile_picture   || defaultAvatar}
            alt={user?.name || 'User'}
            className="user-avatar"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultAvatar;
            }}
          />
          <span className={`status-dot ${userStatus}`}></span>
        </div>
        <div className="user-info">
          <span className="user-name">{user?.name || 'User'}</span>
          <span className="user-role">{user?.role || 'Team Member'}</span>
        </div>
      </div>
    </div>
  );
};

export default NotificationBar; 