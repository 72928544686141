import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaFacebookF, FaLinkedinIn, FaEye, FaEyeSlash, FaChartLine, FaRobot, FaBrain, FaLock, FaEnvelope } from 'react-icons/fa';
import { loginUser } from '../routes/UserRoutes';
import StatusDialog from './Dialog';
import '../components/login.css';
const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(null); // 'loading', 'success', 'error', or null
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const [isDaytime, setIsDaytime] = useState(true);
 
  const [starCount] = useState(200); // More stars
  const [buildingCount] = useState(35); // More buildings
  const [activeShips, setActiveShips] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Add functionality or remove if not needed
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const hour = new Date().getHours();
    setIsDaytime(hour >= 6 && hour < 18);
  }, []);

  useEffect(() => {
  
  }, []);

  useEffect(() => {
    setActiveShips(Array.from({ length: Math.floor(Math.random() * 2) + 2 }, () => ({
      id: Math.random(),
      top: Math.random() * 60 + 10,
      direction: Math.random() > 0.5 ? 'ltr' : 'rtl',
      delay: Math.random() * 2,
      startTime: Date.now(),
    })));

    const interval = setInterval(() => {
      setActiveShips(current => {
        const activeOnes = current.filter(ship => Date.now() - ship.startTime < 20000);
        
        const newShips = Array.from(
          { length: Math.floor(Math.random() * 2) + 1 },
          () => ({
            id: Math.random(),
            top: Math.random() * 60 + 10,
            direction: Math.random() > 0.5 ? 'ltr' : 'rtl',
            delay: Math.random() * 2,
            startTime: Date.now(),
          })
        );
        
        return [...activeOnes, ...newShips].slice(0, 5);
      });
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDialogStatus('loading');
    setIsLoading(true);
  
    try {
      const response = await loginUser(username, password);
      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(response.user));
        if (rememberMe) {
          localStorage.setItem('rememberMe', 'true');
          localStorage.setItem('rememberedUsername', username);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('rememberedUsername');
        }
        setDialogStatus('success');
      } else {
        setErrorMessage(response.message || 'Invalid email or password');
        setDialogStatus('error');
      }
    } catch (err) {
      console.error('Error during login:', err);
      setErrorMessage(err.message || 'An error occurred during login');
      setDialogStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setDialogStatus(null);
    setIsLoading(false);
    navigate('/Dashboard');
  };

  const handleErrorDialogClose = () => {
    setDialogStatus(null);
    setIsLoading(false);
  };

  useEffect(() => {
    const rememberedUsername = localStorage.getItem('rememberedUsername');
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }
  }, []);

  const features = [
    {
      title: "Data as Service",
      description: "Enterprise-grade data solutions and analytics",
      icon: <FaChartLine className="text-xl" />,
      color: "text-blue-500",
      gradient: "from-blue-500/20 to-blue-500/5"
    },
    {
      title: "Smart PO Management",
      description: "Automated workflow optimization",
      icon: <FaRobot className="text-xl" />,
      color: "text-purple-500",
      gradient: "from-purple-500/20 to-purple-500/5"
    },
    {
      title: "Predictive Intelligence",
      description: "AI-powered decision making",
      icon: <FaBrain className="text-xl" />,
      color: "text-indigo-500",
      gradient: "from-indigo-500/20 to-indigo-500/5"
    }
  ];

  const handleForgotPassword = () => {
    // Add your forgot password logic here
    navigate('/forgot-password'); // or show a modal/form
  };

  const handleSignUp = () => {
    // Add your sign up logic here
    navigate('/signup'); // or show a modal/form
  };

  return (
    <div className="min-h-screen w-full flex">
      {/* Light Side - Updated Product Info */}
      <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-slate-50 to-blue-50 relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-pattern opacity-5 animate-pulse"></div>
        
        {/* Add floating elements animation */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute rounded-full mix-blend-multiply filter blur-xl animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                width: `${Math.random() * 400 + 100}px`,
                height: `${Math.random() * 400 + 100}px`,
                background: `radial-gradient(circle, rgba(59,130,246,0.1) 0%, rgba(147,51,234,0.1) 100%)`,
                animationDelay: `${Math.random() * 5}s`,
                animationDuration: `${Math.random() * 10 + 10}s`
              }}
            />
          ))}
        </div>

        {/* Updated feature cards with fixed positioning */}
        <div className="relative w-full max-w-2xl mx-auto px-8 py-12 flex flex-col justify-between h-full">
          {/* Logo and Branding */}
          <div className="mb-8">
            <img src="https://i.ibb.co/vDqg4jC/no-Bg-Color.png" alt="DeltaAnalytics.ai" className="h-12" />
            <h1 className="mt-8 text-4xl font-bold text-gray-900">
              Transform Your Business with
              <span className="block mt-2 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                AI-Powered Analytics
              </span>
            </h1>
            <p className="mt-4 text-lg text-gray-600">
              Join industry leaders using our advanced analytics platform to make data-driven decisions.
            </p>
          </div>

          {/* Feature cards with updated styling */}
          <div className="grid gap-6 mb-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="group bg-white/80 backdrop-blur-lg rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-500 hover:-translate-y-1"
              >
                <div className="flex items-center">
                  <div className={`p-3 rounded-lg bg-gradient-to-br ${feature.gradient} group-hover:scale-110 transition-transform duration-500`}>
                    {feature.icon}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                    <p className="mt-1 text-gray-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Dark Side - Enhanced Login Form with Sci-fi Background */}
      <div className="w-full lg:w-1/2 bg-[#0f172a] flex items-center justify-center px-4 sm:px-6 lg:px-8 relative overflow-hidden">
        {/* Dynamic Background Elements */}
        <div className="absolute inset-0">
          <div className={`absolute inset-0 transition-colors duration-1000 ${
            isDaytime 
              ? 'bg-gradient-to-b from-blue-400/20 via-blue-300/10 to-blue-200/5' 
              : 'bg-gradient-to-b from-gray-900 via-gray-800 to-gray-700'
          }`}>
            {/* Stars (visible at night) */}
            {!isDaytime && [...Array(starCount)].map((_, i) => (
              <div
                key={`star-${i}`}
                className="absolute rounded-full"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  width: `${Math.random() * 2 + 1}px`,
                  height: `${Math.random() * 2 + 1}px`,
                  backgroundColor: `rgba(255, 255, 255, ${Math.random() * 0.5 + 0.5})`,
                  boxShadow: `0 0 ${Math.random() * 2 + 1}px rgba(255, 255, 255, ${Math.random() * 0.3 + 0.2})`,
                  animation: `twinkle ${Math.random() * 3 + 4}s ease-in-out infinite`,
                  animationDelay: `${Math.random() * 5}s`,
                }}
              />
            ))}

            {/* Moon (visible at night) */}
            {!isDaytime && (
              <div className="absolute top-10 right-20">
                <div className="relative w-24 h-24">
                  <div className="absolute inset-0 rounded-full bg-gradient-to-br from-gray-100 to-gray-300 opacity-90"></div>
                  <div className="absolute inset-2 rounded-full bg-gradient-to-br from-gray-200 to-gray-400 opacity-80"></div>
                  {/* Moon craters */}
                  <div className="absolute top-4 left-4 w-4 h-4 rounded-full bg-gray-300/50"></div>
                  <div className="absolute top-8 right-6 w-3 h-3 rounded-full bg-gray-300/50"></div>
                  <div className="absolute bottom-4 left-8 w-2 h-2 rounded-full bg-gray-300/50"></div>
                  {/* Moon glow */}
                  <div className="absolute -inset-4 rounded-full bg-white/10 blur-xl"></div>
                </div>
              </div>
            )}

            {/* Day-specific elements */}
            {isDaytime && (
              <>
                {/* Sun */}
                <div className="absolute top-10 right-20 w-32 h-32">
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-yellow-200 to-yellow-400 blur-xl opacity-50"></div>
                  <div className="absolute inset-4 rounded-full bg-gradient-to-r from-yellow-100 to-yellow-300"></div>
                </div>

                {/* Clouds */}
                {[...Array(6)].map((_, i) => (
                  <div
                    key={`cloud-${i}`}
                    className="absolute animate-float-slow"
                    style={{
                      left: `${i * 20}%`,
                      top: `${10 + Math.random() * 20}%`,
                      animationDelay: `${i * 2}s`,
                      opacity: 0.6,
                    }}
                  >
                    <div className="relative">
                      <div className="w-24 h-8 bg-white/40 rounded-full blur-sm"></div>
                      <div className="absolute top-1 w-16 h-6 bg-white/30 rounded-full blur-sm"></div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* City Buildings */}
            <div className="absolute bottom-0 left-0 right-0 h-64">
              <div className="relative h-full">
                {/* City base glow */}
                <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-blue-500/30 to-transparent"></div>
                
                {/* Buildings */}
                {[...Array(buildingCount)].map((_, i) => {
                  const height = Math.random() * 150 + 50;
                  const width = Math.random() * 12 + 8;
                  const isMainBuilding = Math.random() > 0.7;
                  
                  return (
                    <div
                      key={`building-${i}`}
                      className="absolute bottom-0"
                      style={{
                        left: `${(i / buildingCount) * 100}%`,
                        height: `${height}px`,
                        width: `${width}px`,
                        zIndex: isMainBuilding ? 2 : 1,
                      }}
                    >
                      {/* Building Body */}
                      <div className={`absolute bottom-0 w-full h-full ${
                        !isDaytime ? 'bg-gray-900' : 'bg-gray-300'
                      }`}>
                        {/* Building top with accent light */}
                        <div className="absolute top-0 left-0 right-0 h-2">
                          <div className={`w-full h-full ${
                            !isDaytime ? 'bg-blue-500/30' : 'bg-gray-400'
                          }`}></div>
                          {!isDaytime && (
                            <div className="w-full h-full bg-blue-400/20 animate-pulse"></div>
                          )}
                        </div>

                        {/* Windows Grid */}
                        <div className="absolute inset-0 grid grid-cols-2 gap-[2px] p-[2px]">
                          {[...Array(Math.floor(height / 10))].map((_, j) => (
                            <div
                              key={j}
                              className={`relative ${
                                !isDaytime && Math.random() > 0.4 ? 'animate-window-glow' : ''
                              }`}
                            >
                              <div
                                className={`w-full h-2 rounded-sm ${
                                  !isDaytime
                                    ? Math.random() > 0.3
                                      ? 'bg-yellow-100/80'
                                      : 'bg-gray-800'
                                    : 'bg-gray-200/30'
                                }`}
                                style={{
                                  boxShadow: !isDaytime && Math.random() > 0.3
                                    ? '0 0 10px rgba(255, 244, 180, 0.5), 0 0 20px rgba(255, 244, 180, 0.3)'
                                    : 'none',
                                }}
                              />
                            </div>
                          ))}
                        </div>

                        {/* Building Accents */}
                        {isMainBuilding && (
                          <>
                            {/* Antenna with Blinking Light */}
                            <div className="absolute -top-6 left-1/2 transform -translate-x-1/2">
                              <div className="w-[2px] h-8 bg-gray-700">
                                <div className={`absolute top-0 w-2 h-2 -left-[3px] rounded-full ${
                                  !isDaytime ? 'bg-red-500 animate-pulse' : 'bg-gray-500'
                                }`}></div>
                              </div>
                            </div>

                            {/* Vertical Light Strips */}
                            {!isDaytime && (
                              <div className="absolute inset-x-0 h-full">
                                <div className="absolute left-0 w-[1px] h-full bg-blue-400/30 animate-pulse"></div>
                                <div className="absolute right-0 w-[1px] h-full bg-blue-400/30 animate-pulse"></div>
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {/* Building Reflection */}
                      {!isDaytime && (
                        <div className="absolute -bottom-10 left-0 right-0 h-10 bg-gradient-to-b from-blue-400/10 to-transparent 
                               transform scale-x-[-1] opacity-30"></div>
                      )}
                    </div>
                  );
                })}

                {/* City Ground Reflection */}
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-blue-500/20 to-transparent"></div>
              </div>
            </div>

            {/* Spaceships */}
            {activeShips.map((ship) => (
              <div
                key={ship.id}
                className="absolute"
                style={{
                  top: `${ship.top}%`,
                  [ship.direction === 'ltr' ? 'left' : 'right']: '-80px',
                  animation: `float-ship-${ship.direction} 20s linear forwards`,
                  animationDelay: `${ship.delay}s`,
                }}
              >
                <div className={`relative group ${ship.direction === 'rtl' ? 'scale-x-[-1]' : ''}`}>
                  {/* Ship Body */}
                  <div className={`w-24 h-6 bg-gradient-to-br ${
                    isDaytime 
                      ? 'from-white to-gray-200' 
                      : 'from-slate-100 to-slate-300'
                  } rounded-full transform -skew-x-15 shadow-lg relative overflow-hidden`}>
                    {/* Ship details remain the same but with conditional colors */}
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-400/10 to-transparent"></div>
                    
                    <div className="absolute top-1 left-4 w-8 h-3">
                      <div className={`w-full h-full bg-gradient-to-r ${
                        isDaytime 
                          ? 'from-blue-300/90 to-blue-500/90' 
                          : 'from-blue-400/90 to-blue-600/90'
                      } rounded-full`}></div>
                      <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,0.4)_25%,rgba(255,255,255,0.4)_50%,transparent_50%)] bg-[length:2px_2px]"></div>
                    </div>

                    {/* Engine effects */}
                    <div className="absolute -right-1 top-1/2 -translate-y-1/2 flex space-x-[1px]">
                      {[...Array(3)].map((_, j) => (
                        <div key={j} className="relative w-1.5 h-4">
                          <div className={`absolute inset-0 bg-gradient-to-r ${
                            isDaytime 
                              ? 'from-orange-400 to-red-400' 
                              : 'from-blue-500 to-purple-500'
                          } rounded-full`}></div>
                          <div className="absolute inset-0 animate-pulse bg-gradient-to-r from-blue-400 to-purple-400 rounded-full" 
                               style={{ animationDelay: `${j * 0.2}s` }}></div>
                          <div className="absolute -right-2 w-4 h-4 bg-blue-400/40 blur-md"></div>
                        </div>
                      ))}
                    </div>

                    {/* Trail effect */}
                    <div className="absolute -right-24 top-1/2 -translate-y-1/2 w-24 h-[1px] bg-gradient-to-r from-blue-400/50 to-transparent"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Login Container - Medium Size with all features */}
        <div className="w-full max-w-md relative z-10 mx-auto">
          <div className="bg-gray-800/40 backdrop-blur-xl rounded-2xl p-8 shadow-2xl border border-gray-700/50 
              hover:border-blue-500/50 transition-all duration-500 
              relative overflow-hidden group">
            {/* Enhanced container effects */}
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-blue-500/10 
                group-hover:from-blue-500/20 group-hover:via-purple-500/20 group-hover:to-blue-500/20 
                transition-all duration-500"></div>
            
            {/* Animated border gradient */}
            <div className="absolute -inset-[1px] bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500 
                rounded-2xl opacity-0 group-hover:opacity-30 transition-opacity duration-500"></div>

            {/* Login Form Content */}
            <div className="relative space-y-6">
              <div>
                <img 
                  src="https://i.ibb.co/vDqg4jC/no-Bg-Color.png" 
                  alt="Logo" 
                  className="h-14 mx-auto"
                />
                <div className="mt-8 text-center space-y-2">
                  <h2 className="text-2xl font-bold">
                    <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                      Welcome to the Future
                    </span>
                  </h2>
                  <p className="text-gray-400 text-sm">
                    Access your AI-powered workspace
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Email Input */}
                <div>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaEnvelope className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="block w-full pl-10 pr-3 py-2 border border-gray-600 rounded-lg 
                        bg-gray-700/50 text-white placeholder-gray-400
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Email address"
                      required
                    />
                  </div>
                </div>

                {/* Password Input */}
                <div>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaLock className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="block w-full pl-10 pr-10 py-2 border border-gray-600 rounded-lg 
                        bg-gray-700/50 text-white placeholder-gray-400
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Password"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <FaEyeSlash className="h-5 w-5 text-gray-400 hover:text-gray-300" />
                      ) : (
                        <FaEye className="h-5 w-5 text-gray-400 hover:text-gray-300" />
                      )}
                    </button>
                  </div>
                </div>

                {/* Remember Me Checkbox */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-600 rounded bg-gray-700/50"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-300">
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm">
                    <button 
                      onClick={handleForgotPassword} 
                      className="font-medium text-blue-400 hover:text-blue-300"
                    >
                      Forgot password?
                    </button>
                  </div>
                </div>

                {/* Sign In Button */}
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg
                    text-sm font-medium text-white bg-blue-600 hover:bg-blue-700
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Signing in...
                    </span>
                  ) : (
                    'Sign in'
                  )}
                </button>

                {/* Social Login Options */}
                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-600"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-gray-800 text-gray-400">Or continue with</span>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-3 gap-3">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-600 rounded-lg
                        bg-gray-700/50 text-sm font-medium text-gray-300 hover:bg-gray-700
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      <FaGoogle className="h-5 w-5 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-600 rounded-lg
                        bg-gray-700/50 text-sm font-medium text-gray-300 hover:bg-gray-700
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      <FaFacebookF className="h-5 w-5 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-600 rounded-lg
                        bg-gray-700/50 text-sm font-medium text-gray-300 hover:bg-gray-700
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      <FaLinkedinIn className="h-5 w-5 text-gray-400" />
                    </button>
                  </div>
                </div>
              </form>

              {/* Sign Up Link */}
              <div className="text-center text-sm text-gray-400">
                Don't have an account?{' '}
                <button
                  onClick={handleSignUp}
                  className="font-medium text-blue-400 hover:text-blue-300"
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Status Dialog */}
      {dialogStatus && (
        <StatusDialog
          status={dialogStatus}
          onSuccess={handleSuccessDialogClose}
          onClose={handleErrorDialogClose}
          errorMessage={errorMessage}
          action="login"
        />
      )}
    </div>
  );
};

export default LoginPage;